import { createApp } from "vue" // ИЗМЕНИТЬ
import App from "./app.vue"
import { createMetaManager, plugin as metaPlugin } from "vue-meta"
import VuePdf from "vue3-pdfjs"
// import infraLayer from "@frontend/allspark/infra/index_beriberu-landing.js";
import UiKit from "@frontend/allspark/uikit/index_beriberu.js";
import Layouts from "@/layouts";
import { register } from "register-service-worker"
import router from "./router";
import borrowApi from "@frontend/allspark/infra//jsonrpc/borrow/index.js";
import tracker from "@frontend/allspark/infra//tracker/index.js";
import offerswitcher from "@frontend/allspark/infra/offerswitcher/index.js";

const infra = {
    tracker: {
        ...tracker,
    },
    jsonrpc: {
        ...borrowApi,
    },
    offerswitcher: {
        ...offerswitcher
    }
}

function installInfa(app) {
    app.config.globalProperties.$infra = infra;
}

register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
        console.log(
            "App is being served from cache by a service worker.\n" +
    "For more details, visit https://goo.gl/AFskqB"
        )
    },
    registered () {
        console.log("Service worker has been registered.")
    },
    cached () {
        console.log("Content has been cached for offline use.")
    },
    updatefound () {
        console.log("New content is downloading.")
    },
    updated () {
        console.log("New content is available; please refresh.")
    },
    offline () {
        console.log("No internet connection found. App is running in offline mode.")
    },
    error (error) {
        console.error("Error during service worker registration:", error)
    }
})


const app = createApp(App)
    .use(router)
    .use(installInfa)
    .use(UiKit)
    .use(Layouts)
    .use(createMetaManager())
    .use(metaPlugin)
    .use(Layouts)
    .use(VuePdf)
await router.isReady()
app.mount("#app")



